<template>
  <div class="footer-container">
    <div class="footer">
      <dl>
        <dt>产品</dt>
        <dd v-for="(item, index) in footerMenu"
        :key="index"  @click="navClick(item)">{{ item.name }}</dd>
      </dl>
      <dl>
        <dt>下载</dt>
        <dd><router-link to='/trynow'>传信客户端</router-link ></dd>
      </dl>
      <dl>
        <dt>价格</dt>
        <dd><router-link to='/channel'>购买源代码</router-link></dd>
        <dd><router-link to='/laws'>法律声明</router-link></dd>
        <dd><router-link to='/use'>使用声明</router-link></dd>
        <dd><router-link to='/promise'>合法用途承诺</router-link></dd>
      </dl>
      <dl>
        <dt>其他</dt>
        <dd><router-link to='/news'>新闻资讯</router-link></dd>
        <dd><router-link to='/companyprofile'>关于我们</router-link></dd>
      </dl>
      <dl style="margin-right: 0;">
        <dt>联系我们</dt>
        <dd class="aboutus_one aboutus-title">010-52817830 (售前咨询)</dd>
        <dd class="aboutus_two aboutus-title">010-52817830 (售后热线)</dd>
        <dd class="aboutus_four aboutus-title">xuyan@leadingtek.com</dd>
        <dd class="aboutus_five aboutus-title">北京市朝阳区北苑路甲13号1号楼北辰泰岳大厦9层</dd>
        <dd class="aboutus_six aboutus-title">周一至周五，9:30 - 17:30, 节假日休息</dd>
      </dl>
      <div class="header_img">
      <img src="../../assets/home/scan-code@2x.png" alt="">
      </div>
    </div>
    <div class="footer_copyright">
      Copyright © 2001-{{new Date().getFullYear()}} LeadingTek. 领雁科技 版权所有        京ICP备13026804号-2 京公网安备11010502049135号
    </div>
    
  </div>
</template>

<script setup lang="ts">
import {  ref, reactive } from "vue";
import { useRouter } from "vue-router";
let $router = useRouter();
const footerMenu = reactive([
  { name: "传信即时通讯", path:"nowmessage"},
  { name: "传信移动办公", path:"work"},
  { name: "开发平台", path:"development"},
  { name: "运营平台", path:"operation"},
  { name: "移动中台", path:"centre"},
  { name: "手机银行", path:"moblebank"},
  { name: "移动展业", path:"moblebusiness"},
  { name: "个人网银", path:"personbank"},
  { name: "企业网银", path:"companybank"}
])

// 底部导航跳转
const navClick = (item) => {
  $router.push({ path: item.path});
}
</script>

<style lang="scss" scoped>
@import "./Footer.scss";


</style>
